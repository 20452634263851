import styled from "styled-components";
import { Typography } from "@mui/material";

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #141b25;
  padding: 0 5%;
`;

export const LoginPanel = styled.div`
  display: flex;
  width: 100%;
  background-color: #2b3342;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 30px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: 750px) {
    flex-direction: row;
  }
`;

export const HeroImage = styled.img`
  display: block;
  width: 100%;
  height: auto;

  @media (min-width: 750px) and (max-width: 950px) {
    width: 50%;
  }

  @media (min-width: 950px) {
    flex: 3;
    width: 60%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  width: 100%;

  @media (max-width: 750px) {
    padding: 20px 0 0 0;
  }

  @media (min-width: 950px) {
    flex: 2;
  }
`;

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Footer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

export const ForgotPasswordLink = styled.a`
  color: #a5e0ff;
  text-decoration: none;
  font-weight: 700;
`;

export const ForgotPasswordText = styled(Typography)`
  color: #ffffff;
  text-align: right;
`;
