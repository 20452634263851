import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Button,
} from "@mui/material";
import api from "../api/config";
import { GetAllPaitent } from "../api/Patient";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useSearch } from "../context/SearchContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #2b3342;
  color: #fbfeff;
  border-radius: 8px;
  &.noPadding {
    padding: 0 !important;
  }
`;

const StyledTableContainer = styled(TableContainer)`
  background-color: #2b3342 !important;
  box-shadow: none !important;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 240px);
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #141b25;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3e526a;
    border-radius: 100px;
  }
  .MuiTableCell-root {
    background-color: #2b3342 !important;
    color: #fbfeff !important;
  }

  .MuiTablePagination-root {
    background-color: #2b3342 !important;
    color: #fbfeff !important;
  }

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background-color: #2b3342;
  }
`;

const StyledPaper = styled(Paper)`
  background-color: #2b3342 !important;
  color: #fbfeff !important;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const StyledTableRow = styled(TableRow)`
  background-color: #2b3342;

  &:hover td {
    background-color: #3b4252 !important;
  }

  &.selected-row td {
    background-color: #0088cf !important;
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 10px;
  &.centered {
    display: flex;
    justify-content: center;
  }
  &.aligned {
    display: flex;
    justify-content: space-between;
  }
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: "#3E526A !important",
    color: "#A5E0FF !important",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "14px",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      backgroundColor: "#3E526A !important",
      color: "#A5E0FF !important",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "14px", 
    },
  },
});

const columns = [
  { id: "client_patient_uuid", label: "Patient ID" },
  { id: "fullName", label: "Full Name" },
  { id: "gender", label: "Gender" },
  { id: "date_of_birth", label: "Birth Date" },
];

const AllPatientsTable = ({ onSelectPatient, onToggleView }) => {
  const classes = useStyles();
  const { searchTerm } = useSearch();
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [maxWidth, setMaxWidth] = useState(100);

  const containerRef = useRef(null);

  const calculateMaxWidth = useCallback((width) => {
    let baseWidth = 100;
    if (width > 500) {
      baseWidth += (width - 500) / 10;
    }
    return baseWidth;
  }, []);

  const updateVisibleColumns = useCallback(() => {
    if (containerRef.current) {
      requestAnimationFrame(() => {
        const containerWidth =
          containerRef.current.getBoundingClientRect().width;
        setMaxWidth(calculateMaxWidth(containerWidth));
        if (containerWidth < 400) {
          setVisibleColumns(
            columns.filter(
              (column) =>
                column.id !== "gender" && column.id !== "client_patient_uuid"
            )
          );
        } else if (containerWidth < 500) {
          setVisibleColumns(
            columns.filter((column) => column.id !== "client_patient_uuid")
          );
        } else {
          setVisibleColumns(columns);
        }
      });
    }
  }, [calculateMaxWidth]);

  useEffect(() => {
    const observer = new ResizeObserver(updateVisibleColumns);
    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [updateVisibleColumns]);

  useEffect(() => {
    const fetchPatients = async () => {
      setLoading(true);
      try {
        let clientUuid = localStorage.getItem("clientuuid");
        const response = await api.get(`${GetAllPaitent}${clientUuid}`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
          },
        });
        const tableData = response.data;
        setPatients(tableData.patients.reverse());
        setTotalRows(tableData.total_patients);
      } catch (error) {
        console.error("Failed to fetch patients", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPatients();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (patient) => {
    setSelectedPatientId(patient.uuid);
    onSelectPatient(patient);
  };

  const handleToggleView = () => {
    setShowTable(!showTable);
    onToggleView(!showTable);
  };

  const filteredPatients = patients.filter(
    (patient) =>
      patient.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.client_patient_uuid
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  return (
    <Container ref={containerRef} className={showTable ? "noPadding" : ""}>
      <StyledTypography
        gutterBottom
        className={showTable ? "aligned" : "centered"}
      >
        {showTable ? "All Patients" : ""}
        <Button
          onClick={handleToggleView}
          style={{ marginLeft: "10px", color: "#0088CF" }}
        >
          {showTable ? <FirstPageIcon /> : <KeyboardTabIcon />}
        </Button>
      </StyledTypography>
      {showTable && (
        <>
          <StyledTableContainer component={StyledPaper}>
            {loading ? (
              <div className="loadingContainer">
                <CircularProgress style={{ color: "#FBFEFF" }} />
              </div>
            ) : (
              <TableWrapper>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      {visibleColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          className={classes.headerCell}
                          style={{
                            maxWidth: `${maxWidth}px`,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPatients.map((patient) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={patient.uuid}
                        onClick={() => handleRowClick(patient)}
                        className={
                          selectedPatientId === patient.uuid
                            ? "selected-row"
                            : ""
                        }
                      >
                        {visibleColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              maxWidth: `${maxWidth}px`,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {column.id === "fullName"
                              ? `${patient.last_name}, ${patient.first_name}`
                              : patient[column.id]}
                          </TableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                    {filteredPatients.length === 0 && (
                      <TableRow style={{ height: "100%" }}>
                        <TableCell
                          colSpan={columns.length}
                          style={{ textAlign: "center", height: "100%" }}
                        >
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableWrapper>
            )}
          </StyledTableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-select": {
                backgroundColor: "#2B3342 !important",
                color: "#FBFEFF !important",
              },
              ".MuiTablePagination-menuItem": {
                backgroundColor: "#2B3342 !important",
                color: "#FBFEFF !important",
                "&:hover": {
                  backgroundColor: "#3b4252 !important",
                },
                "&.Mui-selected": {
                  backgroundColor: "#3b4252 !important",
                },
              },
              ".MuiTablePagination-actions": {
                color: "#FBFEFF !important",
              },
            }}
          />
        </>
      )}
    </Container>
  );
};

export default AllPatientsTable;
