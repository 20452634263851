import React from "react";
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  useTheme,
  ListItemButton,
  Box,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import packageJson from "../../../package.json";

const drawerWidth = 100;

const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  color: selected ? "#A5E0FF" : "#9DA2A7",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  "&:hover": {
    color: "#A5E0FF",
  },
  "& .MuiListItemIcon-root": {
    color: selected ? "#A5E0FF" : "#9DA2A7",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(0),
  },
  "& .MuiListItemText-root": {
    textAlign: "center",
  },
}));

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();

  const drawer = (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div>
        <Divider />
        <List>
          <StyledListItemButton
            selected={location.pathname === "/dashboard"}
            onClick={() => navigate("/dashboard")}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={location.pathname === "/dashboard/groups"}
            onClick={() => navigate("/dashboard/groups")}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Groups" />
          </StyledListItemButton>
        </List>
      </div>
      <Box sx={{ flexGrow: 1 }} />
      <Typography
        variant="body2"
        align="center"
        sx={{ color: "#9DA2A7", mb: 10, fontSize: "0.75rem" }}
      >
        Powered by Nexus-MD @v{packageJson.version}
      </Typography>
    </div>
  );

  return (
    <nav>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#2B3342",
            top: "50px",
          },
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default Sidebar;
