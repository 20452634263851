import React, { useState } from "react";
import { styled } from "@mui/system";
import TopToolbar from "./TopToolbar";
import Sidebar from "./Sidebar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const DrawerWidth = 240;
const ToolbarHeight = 64;

const MainContent = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isMobile",
})(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  padding: "20px",
  marginLeft: isMobile ? 0 : DrawerWidth,
  marginTop: ToolbarHeight,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
});

const BodyWrapper = styled("div")({
  display: "flex",
  flexGrow: 1,
});

const DashboardLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ContentWrapper>
      <TopToolbar handleDrawerToggle={handleDrawerToggle} />
      <BodyWrapper>
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <MainContent open={!isMobile || mobileOpen} isMobile={isMobile}>
          {children}
        </MainContent>
      </BodyWrapper>
    </ContentWrapper>
  );
};

export default DashboardLayout;
