import styled from 'styled-components';
import { Button } from '@mui/material';

const StyledButton = styled(Button)`
  && {
    background-color: #0088cf;
    border-radius: 25px;
    font-family: 'Arimo', sans-serif;
    font-weight: 700;
    font-size: 14px;
    text-transform: none;
    padding: 6px 16px;

    ${({ marginright }) => marginright && `margin-right: ${marginright};`}
    ${({ width }) => width && `width: ${width};`}

    &:hover {
      background-color: #0077b3;
    }
  }
`;

export default StyledButton;
