import { createTheme } from "@mui/material/styles";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";

const muiTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2B3342",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#141B25",
      paper: "#ffffff",
    },
    text: {
      primary: "#FBFEFF",
    },
  },
  typography: {
    fontFamily: "Arimo, sans-serif",
  },
  spacing: 8,
});

const customTheme = {
  colors: {
    primary: "#1976d2",
    background: "#141B25",
    text: "#FBFEFF",
  },
};

const theme = { ...muiTheme, ...customTheme };

export { theme, StyledComponentsThemeProvider };
