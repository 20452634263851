import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/config";
import { LOGIN, SignupUser } from "../../api/Authentication";
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }) => {
    console.log("email", email);
    console.log("api", api);
    console.log("API URL: ", process.env.REACT_APP_API_URL);
    const response = await api.post(LOGIN, { email, password });
    return response.data;
  }
);

export const register = createAsyncThunk("auth/register", async (userData) => {
  const response = await api.post(SignupUser, userData);
  return response.data;
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: undefined,
    token: localStorage.getItem("token") || null,
    clientuuid: localStorage.getItem("clientuuid") || null,
    loggedIn: !!localStorage.getItem("token"),
    status: "idle",
    loading: true,
    error: null,
    showInactivityModal: false,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.loggedIn = false;
      state.clientuuid = null;
      state.loading = false;
      state.showInactivityModal = false;
      localStorage.removeItem("token");
      localStorage.removeItem("clientuuid");
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.loggedIn = true;
      state.loading = false;
    },
    showInactivityModal: (state) => {
      state.showInactivityModal = true;
    },
    hideInactivityModal: (state) => {
      state.showInactivityModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.email;
        state.token = action.payload.access_token;
        state.loggedIn = true;
        state.loading = false;
        localStorage.setItem("token", action.payload.access_token);
        localStorage.setItem("clientuuid", action.payload.organization_uuid);
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(register.pending, (state) => {
        state.status = "loading";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = null;
        state.token = null;
        state.loggedIn = false;
        state.loading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const {
  logout,
  setLoading,
  setUser,
  showInactivityModal,
  hideInactivityModal,
} = authSlice.actions;

export default authSlice.reducer;
