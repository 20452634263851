import React, { useEffect, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  showInactivityModal,
  hideInactivityModal,
} from "../store/slices/authSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: "0px 0px 12px rgb(255 255 255 / 28%)",
  },
}));

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.getContrastText(theme.palette.secondary.main),
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const KeepWorkingButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.getContrastText(theme.palette.primary.main),
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const InactivityTimer = () => {
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.auth.showInactivityModal);
  const [countdown, setCountdown] = useState(60);

  const inactiveTimeoutRef = useRef(null);
  const modalTimeoutRef = useRef(null);
  const countdownIntervalRef = useRef(null);

  const resetTimer = useCallback(() => {
    if (inactiveTimeoutRef.current) clearTimeout(inactiveTimeoutRef.current);
    if (modalTimeoutRef.current) clearTimeout(modalTimeoutRef.current);
    if (countdownIntervalRef.current)
      clearInterval(countdownIntervalRef.current);

    inactiveTimeoutRef.current = setTimeout(() => {
      dispatch(showInactivityModal());
      setCountdown(60);
      modalTimeoutRef.current = setTimeout(() => {
        dispatch(logout());
      }, 60000);

      countdownIntervalRef.current = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }, 15 * 60 * 1000);
  }, [dispatch]);

  const handleUserActivity = useCallback(() => {
    resetTimer();
  }, [resetTimer]);

  const handleKeepWorking = () => {
    dispatch(hideInactivityModal());
    resetTimer();
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetTimer();

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      if (inactiveTimeoutRef.current) clearTimeout(inactiveTimeoutRef.current);
      if (modalTimeoutRef.current) clearTimeout(modalTimeoutRef.current);
      if (countdownIntervalRef.current)
        clearInterval(countdownIntervalRef.current);
    };
  }, [handleUserActivity, resetTimer]);

  return (
    <StyledDialog open={showModal} onClose={handleKeepWorking}>
      <DialogTitle>Inactivity Detected</DialogTitle>
      <DialogContent>
        <StyledDialogContentText>
          You have been inactive for 15 minutes. Do you want to keep working?
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <LogoutButton onClick={() => dispatch(logout())}>
          Logout ({countdown} s)
        </LogoutButton>
        <KeepWorkingButton onClick={handleKeepWorking}>
          Keep Working
        </KeepWorkingButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default InactivityTimer;
