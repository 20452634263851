import * as yup from "yup";

const signupValidationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  policyAccepted: yup.bool().oneOf([true], "You must accept the policy"),
  termsAccepted: yup.bool().oneOf([true], "You must accept the terms"),
});

export default signupValidationSchema;
