import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../../store/slices/authSlice";
import { Typography } from "@mui/material";
import { useFormik } from "formik";
import signupValidationSchema from "../../validation/signupValidation";
import StyledTextField from "../../components/shared/StyledTextField";
import StyledButton from "../../components/shared/StyledButton";
import StyledMaskedInput from "../../components/shared/StyledMaskedInput";
import {
  MainContainer,
  LoginPanel,
  Content,
  HeroImage,
  FormContainer,
  Logo,
  Form,
  Footer,
  ForgotPasswordLink,
} from "./Login.styles";

const SignupPage = ({ showNotification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.token) {
      navigate("/dashboard");
    }
  }, [auth.token, navigate]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      policyAccepted: false,
      termsAccepted: false,
    },
    validationSchema: signupValidationSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          register({
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
            login_location: "dashboard",
            phone_number: values.phoneNumber,
            policy_accepted: values.policyAccepted,
            terms_accepted: values.termsAccepted,
            policy_accepted_date: new Date().toISOString(),
            terms_accepted_date: new Date().toISOString(),
          })
        ).unwrap();
        showNotification("Signup successful", "success");
        navigate("/login");
      } catch (error) {
        if (error.status === 400) {
          showNotification(error.data.error, "error");
        } else if (error.status === 422) {
          const validationErrors = error.data.detail
            .map((err) => err.msg)
            .join(", ");
          showNotification(validationErrors, "error");
        } else {
          showNotification("Signup failed", "error");
        }
      }
    },
  });

  return (
    <MainContainer>
      <LoginPanel>
        <Content>
          <HeroImage
            src={require("../../assets/images/login_viewer_list1.png")}
            alt="Hero"
          />
          <FormContainer>
            <Logo
              src={require("../../assets/images/MOVU_logo_white.png")}
              alt="Logo"
            />
            <Form onSubmit={formik.handleSubmit}>
              <StyledTextField
                label="First Name"
                variant="outlined"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                margin="normal"
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
              />
              <StyledTextField
                label="Last Name"
                variant="outlined"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                margin="normal"
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
              />
              <StyledTextField
                label="Email"
                variant="outlined"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                margin="normal"
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              <StyledMaskedInput
                label="Phone Number"
                variant="outlined"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                margin="normal"
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
              />
              <Typography variant="body2" style={{ color: "#ffffff" }}>
                <input
                  type="checkbox"
                  name="policyAccepted"
                  checked={formik.values.policyAccepted}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                I accept the{" "}
                <a
                  href="https://nexus-md.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "#c4c4ff" }}
                >
                  Policy
                </a>
              </Typography>
              <Typography variant="body2" style={{ color: "#ffffff" }}>
                <input
                  type="checkbox"
                  name="termsAccepted"
                  checked={formik.values.termsAccepted}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                I accept the{" "}
                <a
                  href="https://nexus-md.com/terms"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "#c4c4ff" }}
                >
                  {" "}
                  Terms & Conditions
                </a>{" "}
              </Typography>
              <div style={{ marginTop: "20px" }}>
                <StyledButton type="submit" variant="contained" fullWidth>
                  Sign Up
                </StyledButton>
              </div>
            </Form>
            <Footer>
              <Typography variant="body2" style={{ color: "#ffffff" }}>
                Already have an account?{" "}
              </Typography>
              <Typography variant="body2" style={{ color: "#A5E0FF" }}>
                <ForgotPasswordLink href="/login">Log In</ForgotPasswordLink>
              </Typography>
              <Typography
                variant="body2"
                style={{ color: "#9DA2A7", marginTop: "10px" }}
              >
                Powered by Nexus-MD
              </Typography>
            </Footer>
          </FormContainer>
        </Content>
      </LoginPanel>
    </MainContainer>
  );
};

export default SignupPage;
