import { styled } from '@mui/system';
import { TextField } from '@mui/material';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F0F5F7',
    },
    '&:hover fieldset': {
      borderColor: '#F0F5F7',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#F0F5F7',
    },
    borderRadius: '12px',
  },
  '& .MuiInputBase-input': {
    color: '#ffffff',
  },
  '& .MuiInputLabel-root': {
    color: '#ffffff',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiOutlinedInput-input::placeholder': {
    fontStyle: 'italic',
    color: '#F0F5F7',
  },
});

export default StyledTextField;
