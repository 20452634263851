import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import GlobalStyle from "./styles/GlobalStyle";
import { theme } from "./styles/theme";
import LoginPage from "./pages/Auth/Login";
import SignupPage from "./pages/Auth/Signup";
import DashboardLayout from "./components/layout/DashboardLayout";
import Notification from "./components/shared/Notification";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import Home from "./pages/Home";
import Groups from "./pages/Groups";
import { setLoading, setUser } from "./store/slices/authSlice";
import { SearchProvider } from "./context/SearchContext";
import InactivityTimer from "./components/InactivityTimer";

const App = () => {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(setUser());
    } else {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  const showNotification = (message, severity) => {
    setNotification({
      open: true,
      message,
      severity,
    });
  };

  const handleClose = () => {
    setNotification({
      ...notification,
      open: false,
    });
  };

  if (auth.loading) {
    return <div>Loading...</div>;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <StyledComponentsThemeProvider theme={theme}>
        <GlobalStyle />
        <SearchProvider>
          <Router>
            {isLoggedIn && <InactivityTimer />}
            <Routes>
              <Route
                path="/login"
                element={
                  !auth.loading ? (
                    <LoginPage showNotification={showNotification} />
                  ) : (
                    <div>Loading...</div>
                  )
                }
              />
              <Route
                path="/signup"
                element={
                  !auth.loading ? (
                    <SignupPage showNotification={showNotification} />
                  ) : (
                    <div>Loading...</div>
                  )
                }
              />
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/dashboard/*"
                  element={
                    <DashboardLayout>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="groups" element={<Groups />} />
                      </Routes>
                    </DashboardLayout>
                  }
                />
              </Route>
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
            <Notification
              open={notification.open}
              message={notification.message}
              severity={notification.severity}
              handleClose={handleClose}
            />
          </Router>
        </SearchProvider>
      </StyledComponentsThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
