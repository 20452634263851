import * as React from 'react';
import { IMaskInput } from 'react-imask';
import { styled } from '@mui/system';
import { TextField } from '@mui/material';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const StyledMaskedInput = styled((props) => (
  <TextField
    {...props}
    InputProps={{
      inputComponent: TextMaskCustom,
    }}
  />
))({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F0F5F7',
    },
    '&:hover fieldset': {
      borderColor: '#F0F5F7',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#F0F5F7',
    },
    borderRadius: '12px',
  },
  '& .MuiInputBase-input': {
    color: '#ffffff',
  },
  '& .MuiInputLabel-root': {
    color: '#ffffff',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiOutlinedInput-input::placeholder': {
    fontStyle: 'italic',
    color: '#F0F5F7',
  },
});

export default StyledMaskedInput;
