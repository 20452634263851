import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, Typography, TableHead, TableRow, Paper, TablePagination, CircularProgress, IconButton, Collapse, Modal, Backdrop } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShareIcon from '@mui/icons-material/Share';
import SendIcon from '@mui/icons-material/Send';
import api from '../api/config';
import { GetPatientMetaData, GetPatientVitalSigns, GetPatientVitalSigns2, GetPatientStudyMeta } from '../api/Patient';
import { DICOMProxyToken } from '../api/Authentication';
import { ViewerPath, OctViewerPath } from '../api/Authentication';
import StyledButton from './shared/StyledButton';
import axios from 'axios';

const Container = styled.div`
  width: 100%;
  background-color: #2b3342;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoadingPopup = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1300;
`;

const LoadingBackdrop = styled(Backdrop)`
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.8);
`;

const BasicButton = styled(StyledButton)`
  color: #FBFEFF !important;
`;

const Header = styled.div`
  background-color: #0088CF;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
  align-items: center;
  margin-bottom: 16px;
`;

const HeaderTitle = styled.div`
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 15px;
`;

const Button = styled.button`
  background-color: #3b4252;
  color: #FBFEFF;
  border: none;
  border-radius: 100px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #4c566a;
  }
`;

const PatientInfoContainer = styled.div`
  padding: 16px;
  display: flex;
  gap: 16px;
`;

const PatientStudiesContainer = styled.div`
  padding: 0 16px 16px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InfoTable = styled.div`
  background-color: #3b4252;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px #00000040;
  flex: ${({ flex }) => flex || 1};
`;

const TableTitle = styled.p`
  margin-top: 0;
  background-color: #3E526A;
  color: #7EF5FD;
  padding: 10px;
  border-radius: 8px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const MessageContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const StyledTableContainer = styled(TableContainer)`
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: transparent !important;
  flex-grow: 1;
`;

const EhrTable = styled(StyledTableContainer)`
  max-height: 15vh !important;
`;

const StyledPaper = styled(Paper)`
  background-color: transparent !important;
  color: #FBFEFF !important;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledTableRow = styled(TableRow)`
  td {
    padding: 5px;
  }
  &:hover {
    background-color: #5a6285 !important;
  }

  &.selected-row td {
    background-color: #A5E0FF !important;
    color: #000000 !important;
  }
`;

const ExpandedTableRow = styled(TableRow)`
  td {
    background-color: #3b4252 !important;
    border: 2px solid #A5E0FF;
    border-radius: 0 0 8px 8px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const ScrollableTableBody = styled(TableBody)`
  overflow-y: auto;
  flex-grow: 1;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #0088CF;
    opacity: 0.6;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3E526A;
    border-radius: 100px;
  }
`;

const ThumbnailList = styled.div`
  display: flex;
`;

const ThumbnailListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  & img {
    margin-right: 10px;
  }
`;

const OuterContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #28929C;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #0088CF;
    border: 1px solid #3E526A;
    border-radius: 100px;
  }
`;

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: "#3E526A !important",
    color: "#A5E0FF !important",
  },
  rowCell: {
    padding: "8px !important",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      backgroundColor: "#3E526A !important",
      color: "#A5E0FF !important",
    },
  },
});

const columns = [
  { id: 'StudyDescription', label: 'Study Description' },
  { id: 'Modality', label: 'Modality' },
  { id: 'StudyDate', label: 'Study Date' },
];

const PatientDetails = ({ patient, containerWidth }) => {
  const classes = useStyles();
  const [studies, setStudies] = useState(null);
  const [patientStudyMeta, setPatientStudyMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [vitalSigns, setVitalSigns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [vitalSignsLoading, setVitalSignsLoading] = useState(true);
  const [thumbnails, setThumbnails] = useState({});
  const rowRef = useRef(null);
  const [loadingThumbnails, setLoadingThumbnails] = useState({});

  useEffect(() => {
    if (patient) {
      const fetchPatientMetaData = async () => {
        setStudies(null);
        setPatientStudyMeta({});
        setVitalSigns([]);
        setExpandedRows([]);
        setLoading(true);
        setVitalSignsLoading(true);
        setTotalRows(null);
        setThumbnails({});
        try {
          const response = await api.get(`${GetPatientMetaData}/${patient.uuid}`);
          setStudies(response.data);
          setTotalRows(response.data.length);
        } catch (error) {
          console.error('Failed to fetch patient meta data', error);
          setStudies([]);
          setTotalRows(0);
        } finally {
          setLoading(false);
        }
      };

      const fetchPatientVitalSigns = async () => {
        setLoading(true);
        try {
            const response = await api.get(`${GetPatientVitalSigns}/${patient.uuid}`);
            console.log('Files: ', response.data);
        } catch (error) {
            console.error('Failed to fetch patient vital signs', error);
        } finally {
            setLoading(false);
        }
      };

      const fetchPatientVitalSigns2 = async () => {
        try {
          console.log('Fetching patient vital signs 2', patient.uuid);
          const response = await api.get(`${GetPatientVitalSigns2}/${patient.uuid}?simplify=true`);
          setVitalSigns(response.data);
        } catch (error) {
          console.error('Failed to fetch patient vital signs', error);
        } finally {
          setVitalSignsLoading(false);
        }
      };

      fetchPatientMetaData();
      fetchPatientVitalSigns();
      fetchPatientVitalSigns2();
    }
  }, [patient]);

  const fetchPatientStudyMeta = async (studyId) => {
    try {
      const response = await api.get(`${GetPatientStudyMeta}/${studyId}`);
      const studyMeta = response.data[0];
      setPatientStudyMeta(prevState => ({
        ...prevState,
        [studyId]: studyMeta,
      }));
      
      const tokenResponse = await api.get(DICOMProxyToken);
      const token = tokenResponse.data.token;

      for (let series of studyMeta.Series) {
        const instance = series.Instances[0];
        if (instance) {
          const thumbnailUrl = await getThumbnailUrl(studyMeta.StudyInstanceUID, series.SeriesInstanceUID, instance.SOPInstanceUID, token);
          if (thumbnailUrl) {
            setThumbnails(prevThumbnails => ({
              ...prevThumbnails,
              [studyId]: {
                ...(prevThumbnails[studyId] || {}),
                [series.SeriesInstanceUID]: thumbnailUrl
              }
            }));
          }
        }
      }
    } catch (error) {
      console.error('Failed to fetch patient study meta', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleRowExpansion = (studyId) => {
    if (!expandedRows.includes(studyId)) {
      fetchPatientStudyMeta(studyId);
    }
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(studyId)
        ? prevExpandedRows.filter((id) => id !== studyId)
        : [...prevExpandedRows, studyId]
    );
    setLoadingThumbnails(prevState => ({
      ...prevState,
      [studyId]: true,
    }));
  };

  const getThumbnailUrl = async (studyInstanceUID, seriesInstanceUID, sopInstanceUID, DICOMProxyToken) => {
    try {
        const url = `https://healthcare.googleapis.com/v1/projects/nexusmd-prod/locations/us/datasets/dicom.nexus-md.factstore/dicomStores/production_phi/dicomWeb/studies/${studyInstanceUID}/series/${seriesInstanceUID}/instances/${sopInstanceUID}/rendered`;

        const response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${DICOMProxyToken}`,
                'Accept': 'image/jpeg'
            },
            responseType: 'blob'
        });

        if (response.status === 200) {
            const imageObjectURL = URL.createObjectURL(response.data);
            setLoadingThumbnails(prevState => ({
              ...prevState,
              [studyInstanceUID]: false,
            }));
            return imageObjectURL;
        } else {
            setLoadingThumbnails(prevState => ({
              ...prevState,
              [studyInstanceUID]: false,
            }));
            console.error('Non-200 status code:', response.status);
            return null;
        }
    } catch (error) {
        setLoadingThumbnails(prevState => ({
          ...prevState,
          [studyInstanceUID]: false,
        }));
        if (error.response && error.response.status === 400) {
            console.error('Failed to fetch thumbnail URL with 400 error:', error.response.data);
        } else {
            console.error('Failed to fetch thumbnail URL:', error.message);
        }
        return null;
    }
  };

  useEffect(() => {
    if (rowRef.current) {
      console.log("Row Width:", rowRef.current.clientWidth);
    }
  }, [expandedRows, containerWidth]);

  const handleThumbnailClick = async (studyInstanceUID) => {
    try {
      setLoadingPopup(true);
      const tokenResponse = await api.get(DICOMProxyToken);
      const dicomToken = tokenResponse.data.token;
      window.open(`${OctViewerPath}?StudyInstanceUIDs=${studyInstanceUID}&token=${dicomToken}&origin=movu`);
    } catch (error) {
      console.error('Error processing DICOM file:', error);
    } finally {
      setLoadingPopup(false);
    }
  };

  if (!patient) {
    return (
      <Container>
        <MessageContainer>Select a patient to see details</MessageContainer>
      </Container>
    );
  }

  return (
    <Container>
      <LoadingPopup
        open={loadingPopup}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setLoadingPopup(false);
          }
        }}
        BackdropComponent={LoadingBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CircularProgress style={{ color: '#FBFEFF' }} />
      </LoadingPopup>
      <Header>
        <HeaderTitle>
          <h2 style={{ margin: '5px', textTransform: "capitalize" }}>{`${patient.first_name} ${patient.last_name}`}</h2>
          <p style={{ margin: '5px' }}>{patient.client_patient_uuid}</p>
        </HeaderTitle>
        <HeaderButtons>
          <Button>
            <ShareIcon />
            Share Patient
          </Button>
          <Button>
            <SendIcon />
            Send Records
          </Button>
        </HeaderButtons>
      </Header>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <PatientInfoContainer>
          <InfoTable flex={2}>
            <TableTitle>Patient Information</TableTitle>
            <InfoRow>
              <span>Birth Date:</span>
              <span>{patient.date_of_birth}</span>
            </InfoRow>
            <InfoRow>
              <span>Gender:</span>
              <span>{patient.gender}</span>
            </InfoRow>
            <InfoRow>
              <span>Phone:</span>
              <span>{patient.phone || "N/A"}</span>
            </InfoRow>
            <InfoRow>
              <span>Address:</span>
              <span>{`${patient.address_street || ""} ${patient.address_city || ""} ${patient.address_state || ""} ${patient.address_zip || ""}`}</span>
            </InfoRow>
          </InfoTable>
          <InfoTable flex={3}>
            <TableTitle>Patient EHR</TableTitle>
            <EhrTable component={Paper}>
              <Table stickyHeader>
                {vitalSignsLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                        <CircularProgress style={{ color: "#FBFEFF" }} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {vitalSigns.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={3} style={{ textAlign: "center" }}>
                          No EHR data for the patient
                        </TableCell>
                      </TableRow>
                    ) : (
                      [...vitalSigns]
                        .sort((a, b) => new Date(b.effectiveDateTime) - new Date(a.effectiveDateTime)) // Sort by date in descending order
                        .map((vital, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell className={classes.rowCell}>
                                <IconButton size="small" onClick={() => toggleRowExpansion(index)}>
                                  {expandedRows.includes(index) ? <KeyboardArrowDownIcon style={{ filter: "invert(1)" }}/> : <KeyboardArrowRightIcon style={{ filter: "invert(1)" }}/>}
                                </IconButton>
                              </TableCell>
                              <TableCell className={classes.rowCell}>{new Date(vital.effectiveDateTime).toLocaleString()}</TableCell>
                              <TableCell className={classes.rowCell}>{vital.status}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                                  <Table size="small">
                                    <TableBody>
                                      {vital.components.map((component, compIndex) => (
                                        <TableRow key={compIndex}>
                                          <TableCell>{component.code}</TableCell>
                                          <TableCell>{`${component.value} ${component.unit}`}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                  )}
                </TableBody>
                )}
              </Table>
            </EhrTable>
          </InfoTable>
        </PatientInfoContainer>
        <PatientStudiesContainer>
          <InfoTable>
            <Typography style={{ marginBottom: "10px" }}>Patient Studies ({totalRows !== null ? totalRows : 'Loading...'})</Typography>
            <StyledTableContainer component={StyledPaper}>
              {studies === null ? (
                <div className="loadingContainer">
                  <CircularProgress style={{ color: "#FBFEFF" }} />
                </div>
              ) : (
                <TableWrapper>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.headerCell}></TableCell>
                        {columns.map((column) => (
                          <TableCell key={column.id} className={classes.headerCell}>
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell className={classes.headerCell}></TableCell>
                      </TableRow>
                    </TableHead>
                    <ScrollableTableBody>
                      {studies.length === 0 ? (
                        <TableRow style={{ height: "100%" }}>
                          <TableCell colSpan={columns.length + 2} style={{ textAlign: 'center', height: '100%' }}>
                            No Studies Available
                          </TableCell>
                        </TableRow>
                      ) : (
                        studies.map((study) => (
                          <React.Fragment key={study.StudyInstanceUID}>
                            <StyledTableRow hover role="checkbox" tabIndex={-1} onClick={() => toggleRowExpansion(study.StudyInstanceUID)} className={expandedRows.includes(study.StudyInstanceUID) ? "selected-row" : ""}>
                              <TableCell>
                                <IconButton size="small">
                                  {expandedRows.includes(study.StudyInstanceUID) ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon style={{ filter: "invert(1)" }}/>}
                                </IconButton>
                              </TableCell>
                              <TableCell>{study.StudyDescription || 'N/A'}</TableCell>
                              <TableCell>{study.Modality}</TableCell>
                              <TableCell>{study.StudyDate}</TableCell>
                              <TableCell>
                                <IconButton size="small">
                                  <MoreVert />
                                </IconButton>
                              </TableCell>
                            </StyledTableRow>
                            {expandedRows.includes(study.StudyInstanceUID) && (
                              <ExpandedTableRow ref={rowRef}>
                                <TableCell colSpan={columns.length + 2} style={{ padding: "16px", maxWidth: "50px" }}>
                                  <div style={{ paddingBottom: 16, display: 'flex', justifyContent: 'flex-start' }}>
                                    <BasicButton onClick={() => {
                                        handleThumbnailClick(
                                          study.StudyInstanceUID
                                        );
                                    }}>
                                      Basic Viewer
                                    </BasicButton>
                                  </div>
                                  <OuterContainer>
                                  <ThumbnailList>
                                    {loadingThumbnails[study.StudyInstanceUID] ? (
                                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', overflow: 'hidden' }}>
                                        <CircularProgress style={{ color: "#FBFEFF" }} />
                                      </div>
                                    ) : (
                                      patientStudyMeta[study.StudyInstanceUID] && patientStudyMeta[study.StudyInstanceUID].Series.map(series => (
                                        thumbnails[study.StudyInstanceUID] && thumbnails[study.StudyInstanceUID][series.SeriesInstanceUID] ? (
                                          <ThumbnailListItem key={series.SeriesInstanceUID}>
                                            <img 
                                              src={thumbnails[study.StudyInstanceUID][series.SeriesInstanceUID]} 
                                              alt="Thumbnail" 
                                              width={170} 
                                              height={170} 
                                              onClick={() => handleThumbnailClick(study.StudyInstanceUID)} 
                                            />
                                          </ThumbnailListItem>
                                        ) : null
                                      ))
                                    )}
                                  </ThumbnailList>
                                  </OuterContainer>
                                </TableCell>
                              </ExpandedTableRow>
                            )}
                          </React.Fragment>
                        ))
                      )}
                    </ScrollableTableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalRows || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "#3b4252",
                      zIndex: 2,
                      ".MuiTablePagination-select": {
                        backgroundColor: "#3b4252 !important",
                        color: "#FBFEFF !important",
                      },
                      ".MuiTablePagination-menuItem": {
                        backgroundColor: "#3b4252 !important",
                        color: "#FBFEFF !important",
                        "&:hover": {
                          backgroundColor: "#2B3342 !important",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#2B3342 !important",
                        },
                      },
                      ".MuiTablePagination-actions": {
                        color: "#FBFEFF !important",
                      },
                      boxShadow: "0px -2px 4px 0px #141B25",
                      overflow: "visible",
                    }}
                  />
                </TableWrapper>
              )}
            </StyledTableContainer>
          </InfoTable>
        </PatientStudiesContainer>
      </div>
    </Container>
  );
};

export default PatientDetails;